/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { TimeInputProps, TimeSelectorProps, TimeSelectorFields } from '../IWidgetProps'
import { TimeInput } from './TimeInput'

export const TimeFields = ({
  isDisabled,
  timeSelector,
  currentMode,
  timeValue,
  dateValue,
  setTimeValue,
  submit
}: TimeSelectorProps) => {
  const [focusedInput, setFocusedInput] = useState<string | null>(null)
  const fieldsMap = new Map<TimeSelectorFields, Partial<TimeInputProps>>([
    ['hh', { range: [0, 23] }],
    ['mm', { range: [0, 59] }],
    ['ss', { range: [0, 59] }]
  ])
  
  const rightPartRange = useCallback((range: TimeInputProps['range'], selectorIndex: number): TimeInputProps['range'] => {
    if (!range?.length) return range
    if (currentMode === 'single' || (!dateValue[1] || dateValue[0] === dateValue[1])) {
      return selectorIndex === 0
        ? [timeValue[0][selectorIndex], range[1]]
        : [timeValue[0][selectorIndex - 1] < timeValue[1][selectorIndex - 1] ? range[0] : timeValue[0][selectorIndex], range[1]]
    }
    return range
  }, [timeValue, dateValue, currentMode])

  const getNextFocusField = (part: number, index: number) => {
    const isLast = timeSelector.fields[index + 1] === undefined
    let targetPart = part
    const targetField = !isLast ? timeSelector.fields[index + 1] : timeSelector.fields[0]
    const targetIndex = !isLast ? index + 1 : 0
    
    if (isLast) {
      if (timeSelector.mode === 'pair') {
        targetPart = part === 1 ? 0 : 1
      }
    }

    return `${targetField}-${targetPart}-${targetIndex}`
  }

  const generateFields = (part: number, isRight = false) => (
    timeSelector.fields.map((field, index, arr) => (
      field &&
        <Fragment key={`${field}-${part}-${index}`}>
          <TimeInput
            value={timeValue[part][index]}
            setValue={(value) => setTimeValue({ part, index, value })}
            switchInput={() => setFocusedInput(getNextFocusField(part, index))}
            resetFocusState={() => setFocusedInput(null)}
            isFocused={focusedInput === `${field}-${part}-${index}`}
            apply={submit}
            digits={2}
            {...fieldsMap.get(field)}
            range={!isRight ? fieldsMap.get(field)?.range : rightPartRange(fieldsMap.get(field)?.range, index)}
          />
          {index < arr.length - 1 && <span>:</span>}
        </Fragment>
    ))
  )

  return (
    <div css={dateFilterActionsCSS}>
      <div
        css={timeSelectorCSS}
        style={{
          opacity: isDisabled ? 0.25 : 1,
          pointerEvents: isDisabled ? 'none' : 'initial' 
        }}
      >
        {generateFields(0)}
        {timeSelector?.mode === 'pair'
          && <Fragment><span> - </span> {generateFields(1, true)}</Fragment>
        }
      </div>
    </div>
  )
}

const dateFilterActionsCSS = css`
  padding: 0 var(--spacer) var(--spacer-xs);
`

const timeSelectorCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacer-sm);
`
