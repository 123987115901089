import { TLocales } from '../IWidgetProps'
import { localesRU } from './locales.ru'
import { localesEN } from './locales.en'

export const locales: TLocales = {
  ru: localesRU,
  en: localesEN
}

export type ILocale = typeof locales
