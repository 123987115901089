/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { DaysPresetsProps } from '../IWidgetProps'
import { useLocale } from '../hooks/useLocale'
import { Dropdown, DropdownChangeParams } from 'primereact/dropdown'
import { datesToPeriods, periodsToDates } from '../utils'
import { isEqual } from 'lodash-es'

export const DaysPresets = ({ presets, dateValue, setDateValue, setRangePeriod }: DaysPresetsProps) => {
  const { $t } = useLocale()
  const [selectedPreset, setSelectedPreset] = useState<typeof presetsDict[number] | null>(null)

  const presetsDict = presets.map((preset) => ({
    name: $t<string>(`_rangePresets.${preset}`),
    code: preset
  }))

  const selectPreset = ({ value }: DropdownChangeParams) => {
    setSelectedPreset(value)
    setDateValue(periodsToDates(value.code))
    setRangePeriod(value.code)
  }

  useEffect(() => {
    if (!dateValue.length) {
      setSelectedPreset(null)
    }

    const preset = datesToPeriods(dateValue)
    if (preset) {
      setSelectedPreset(presetsDict.find(({ code }) => isEqual(code, preset)) || null)
    } else {
      setSelectedPreset(null)
    }

    setRangePeriod(preset || null)
  }, [dateValue])

  return (
    <Dropdown
      value={selectedPreset}
      options={presetsDict}
      appendTo='self'
      optionLabel='name'
      css={presetSelectorCSS}
      placeholder={$t('_rangePresetsPlaceholder')}
      onChange={selectPreset}
    />
  )
}

const presetSelectorCSS = css`
  width: 100%;
  box-shadow: var(--shadow-raised);
  background: var(--input-bg-filled);
  border: var(--input-border-width) solid var(--input-bg-filled);

  .p-dropdown-panel {
    top: auto !important;
    bottom: 100% !important;
  }
`
