import React, { FC, ReactNode, useContext } from 'react'
import { WidgetConfig } from '../IWidgetProps'

type MountContextProps = Pick<WidgetConfig, 'mountChildren' | 'unmountSelf' | 'areas'>

const MountContext = React.createContext<MountContextProps>(null!)

export const useMount = () => useContext(MountContext)

export const MountProvider: FC<{ data: MountContextProps, children: ReactNode }> = ({ data, children }) => {
  return (
    <MountContext.Provider value={data}>
      {children}
    </MountContext.Provider>
  )
}
