import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { addLocale } from 'primereact/api'
import { locales } from '../locales'
import { LocaleCodes, TLocales } from '../IWidgetProps'
import { get, merge } from 'lodash-es'

interface ILocaleContext {
  currentLocale: LocaleCodes
  setLocale: (locale: LocaleCodes) => void
  $t: <T,>(path: string, ...rest: Array<string | number>) => T | string
}

const LocaleContext = React.createContext<ILocaleContext>({
  currentLocale: 'ru',
  setLocale: (locale: LocaleCodes) => {},
  $t: <T,>(path: string, ...rest: Array<string | number>): T | string => path,
})

export const useLocale = () => useContext(LocaleContext)

export const LocaleProvider: FC<{ value?: TLocales, children: ReactNode }> = ({value, children}) => {
  value = merge(locales, value)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [currentLocale, setLocale] = useState<LocaleCodes>('ru')
  const $t = <T,>(path: string, ...rest: Array<string | number>): T | string => {
    const data = get(value, `${currentLocale}.${path}`)
    if (typeof data !== 'string') return data as T
    const message: string = String(data)
    return !rest.length ?
      message :
      message.split(' ').reduce((acc, next) => {
        acc.push(
          next.includes('{param}') ?
            next.replace('{param}', String(rest.shift())) :
            next
        )
        return acc
      }, [] as Array<string | number>).join(' ')
  }

  useEffect(() => {
    if (isFirstRender) {
      setLocale(localStorage.getItem('netvision:locale') as LocaleCodes || 'ru')
      addLocale(currentLocale, get(value, `${currentLocale}._dateTimeLocale`))
      setIsFirstRender(false)
    }
  }, [isFirstRender, currentLocale, value])

  useEffect(() => {
    if (!isFirstRender && currentLocale) {
      localStorage.setItem('netvision:locale', currentLocale)
      addLocale(currentLocale, get(value, `${currentLocale}._dateTimeLocale`))
    }
  }, [currentLocale, value, isFirstRender])

  return (
    <LocaleContext.Provider value={{ $t, currentLocale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
